import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Input from 'components/Input';
// import Cookie from 'js-cookie';

import { signInRequest } from 'store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    // Cookie.set('user', email + password);
    // Cookie.remove('user');
    dispatch(signInRequest(email, password));
  }

  return (
    <>
      <Form onSubmit={handleSubmit} schema={schema}>
        <Input
          label="E-mail"
          name="email"
          type="email"
          placeholder="Seu e-mail"
        />
        <Input
          label="Senha"
          name="password"
          type="password"
          placeholder="Sua senha"
        />
        {/* <Input name="id" type="password" placeholder="Seu id" /> */}

        <button type="submit">{loading ? 'Carregando...' : 'Entrar'}</button>
        {/* <Link to="/register">Criar conta gratuita</Link> */}

        <Link to="/register">Ainda nao tem login? Cadastre-se</Link>
        <Link to="/">Esqueceu sua senha? Recupere aqui.</Link>
      </Form>
    </>
  );
}
