import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from 'services/history';
import { signOut } from 'store/modules/auth/actions';

export default function RedirectStage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut());
    history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <span>saindo...</span>
    </>
  );
}
