import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'row bg-white' })`
  border-top: 5px solid #00a0c5;
  padding-top: 2px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const LogoGrowth = styled.img.attrs({
  width: '160',
  src: '/logogrowthtech.png',
  alt: 'Logo da GrowthTech',
})``;
export const LogoIBM = styled.img.attrs({
  src: '/logoibm.jpg',
  alt: 'Logo da IBM',
})``;
