import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/img_logo_servicosdigitais.png';

import { Wrapper, Content, ContainerImg } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Wrapper>
      <ContainerImg src={logo} alt="applogo" maxSize={240} />

      <Content>{children}</Content>
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
