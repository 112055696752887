import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import RedirectStage from 'pages/RedirectStage';
import Logout from 'pages/Logout';
import Route from './Routes';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/redirect" component={RedirectStage} isPrivate />
      <Route path="/logout" component={Logout} isPrivate />

      <Redirect to="/" />
    </Switch>
  );
}
