/* eslint-disable react/prop-types */
import React from 'react';
// import colors from 'styles/defaultColors';

import { Container, ContainerLogo, Logo } from './styles';

export default function Footer({ logo, url, description }) {
  return (
    <Container>
      <ContainerLogo>
        <Logo src={logo} />
      </ContainerLogo>
      <p>{description}</p>
      <button type="button" onClick={() => window.location.assign(url)}>
        ACESSAR
      </button>
    </Container>
  );
}
