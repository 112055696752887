import React from 'react';
import colors from 'styles/defaultColors';

import { Container, LogoGrowth, LogoIBM } from './styles';

export default function Footer() {
  return (
    <Container>
      <div
        className="col py-2"
        style={{ borderTop: `5px solid ${colors.darkBlue}` }}
      >
        <div className="row align-items-center">
          <div className="col-6 col-md-3 order-2 order-md-1 text-left">
            <LogoGrowth />
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2 text-center">
            <p className="m-0">
              Av. Almirante Barroso, 81 - Centro - Rio de Janeiro
            </p>
            <p className="m-0">
              <a href="https://www.growthtech.com.br">www.growthtech.com.br</a>
              &nbsp;|&nbsp;
              <a href="mailto:comercial@growthtech.com.br">
                comercial@growthtech.com.br
              </a>
            </p>
          </div>
          <div className="col-6 col-md-3 order-3 order-md-3 text-right">
            <LogoIBM />
          </div>
        </div>
      </div>
    </Container>
  );
}
