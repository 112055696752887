/* eslint-disable react/prop-types */
import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
// import colors from 'styles/defaultColors';

import { Container, Img } from './styles';

export default function CheckItem({ status, label }) {
  return (
    <Container status={status}>
      <MdCheckCircle />
      <p>{label}</p>
    </Container>
  );
}
