import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import history from 'services/history';
import { toast } from 'react-toastify';

import Cookie from 'js-cookie';
import { Types, signInSuccess, signFailure, signUpSuccess } from './actions';

function setCookie(token) {
  Cookie.set(process.env.REACT_APP_COOKIE_KEY, token, {
    domain: '.growthtech.com.br',
    expires: 365,
    path: '/',
    secure: true,
  });
}

export function* singIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, '/auth', { email, password });

    const { token, name } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setCookie(token);

    yield put(signInSuccess(token, name, email));
    toast.success('Autenticado');
    history.push('/redirect');
  } catch (error) {
    Cookie.remove(process.env.REACT_APP_COOKIE_KEY);
    toast.error('Email e/ou senha estão errados');
    yield put(signFailure());
  }
}

export function* singUp({ payload }) {
  const { data } = payload;
  try {
    if (data.type === 'PF') {
      yield call(api.post, '/register/pf', {
        name: data.name,
        email: data.email,
        cpf: data.cpf,
        password: data.password,
      });
    } else if (data.type === 'PJ') {
      yield call(api.post, '/register/pj', {
        company: data.name,
        email: data.email,
        cnpj: data.cnpj,
        password: data.password,
      });
    }

    toast.success('Conta criada com sucesso.');
    history.push('/');
    yield put(signUpSuccess());
  } catch (error) {
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    setCookie(token);
  }
}

export function signOut() {
  Cookie.remove(process.env.REACT_APP_COOKIE_KEY);
  toast.warn('Sua conexão expirou.');
  history.push('/');
}

export default all([
  takeLatest(Types.SIGN_IN_REQUEST, singIn),
  takeLatest(Types.SIGN_UP_REQUEST, singUp),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(Types.SIGN_OUT, signOut),
]);
