/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from 'services/history';
import colors from 'styles/defaultColors';

import { signOut } from 'store/modules/auth/actions';

import { Container, Logo } from './styles';

export default function Header() {
  // const user = useSelector(state => state.user.profile);
  const signed = useSelector(state => state.auth.signed);
  // const currentPage = useSelector(state => state.general.currentPage);

  const dispatch = useDispatch();

  function handleLogoClick() {
    history.push('/');
  }

  function handleLogout() {
    dispatch(signOut());
    window.localStorage.clear();
    window.location.assign(`${process.env.REACT_APP_BASE_URL_LOGIN}`);
  }

  return (
    <Container>
      <div className="col py-2" style={{ borderBottom: '5px solid #00a0c5' }}>
        <div className="row align-items-end">
          <div className="col">
            <Logo onClick={handleLogoClick} src="/logogrowthtech.png" />
          </div>

          {signed && (
            <div className="col">
              <div className="row h-50 align-items-end justify-content-end">
                <div className="col-auto">
                  <p className="m-0 text-right">
                    {/* <span style={{ color: colors.darkGray, fontSize: '21px' }}>
                      {currentPage}
                    </span> */}
                  </p>
                  <p className="m-0 text-right">
                    {1 === 1 && (
                      <span
                        onClick={handleLogout}
                        style={{
                          color: colors.darkGray,
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                      >
                        Sair
                      </span>
                    )}
                    {/* {currentPage === 'Home' && (
                      <span
                        onClick={handleLogout}
                        style={{
                          color: colors.darkGray,
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                      >
                        Sair
                      </span>
                    )} */}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
