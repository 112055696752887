import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;

  background-color: #e5e5e5;
  z-index: 10;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  height: 260px;
  margin: 24px;
  padding: 16px;
  box-shadow: 0px 4px 6px rgba(158, 158, 158, 0.5);
  :hover {
    box-shadow: 0px 4px 8px rgba(158, 158, 158, 0.75);
  }

  p {
    color: #8e8e8e;
    font-size: 14px;
    margin: 4px 0;
  }

  button {
    background-color: #ec9413;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const ContainerLogo = styled.div`
  max-height: 60px;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Logo = styled.img`
  /* max-height: 50%; */

  min-height: 40px;
  max-height: 60px;
  vertical-align: top;
`;
