import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const ContainerImg = styled.img`
  max-width: 720px;
  width: 80%;
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
`;
