import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/logonotaryledgers.png';

import Header from 'components/Header';
import Footer from 'components/Footer';

import { Wrapper, Content, ContainerImg } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Header />
      {/* <ContainerImg src={logo} alt="applogo" /> */}
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
