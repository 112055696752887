import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { signUpRequest } from 'store/modules/auth/actions';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import CheckItem from 'components/CheckItem';
import ValidadeCpf from 'utils/ValidateCpf';
import ValidadeCNPJ from 'utils/ValidadeCNPJ';

export default function SignUp() {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState('PF');
  const [ruleTamMin8, setRuleTamMin8] = useState(undefined);
  const [ruleMin1, setRuleMin1] = useState(undefined);
  const [ruleMai1, setRuleMai1] = useState(undefined);
  const [ruleHaveLetter, setRuleHaveLetter] = useState(undefined);
  const [ruleHaveNumber, serRuleHaveNumber] = useState(undefined);

  function checkRules(value) {
    setRuleTamMin8(value.length >= 8);

    const regexLetter = RegExp('[a-zA-Z]', 'g');
    setRuleHaveLetter(regexLetter.test(value));

    const regexLetterMin = RegExp('[a-z]', 'g');
    setRuleMin1(regexLetterMin.test(value));

    const regexLetterMai = RegExp('[A-Z]', 'g');
    setRuleMai1(regexLetterMai.test(value));

    const regexNumber = RegExp('[0-9]', 'g');
    serRuleHaveNumber(regexNumber.test(value));
  }

  const loading = useSelector(state => state.auth.loading);

  function handleSubmit(data) {
    if (data.cpf) {
      data.cpf = data.cpf
        .split('.')
        .join('')
        .replace('-', '');
      if (!ValidadeCpf(data.cpf)) {
        toast.warn('CPF inválido');
        return -1;
      }
      data.cpf = data.cpf.replace(/[.-]/gi, '');
    }
    if (data.cnpj) {
      data.cnpj = data.cnpj
        .split('.')
        .join('')
        .replace('/', '')
        .replace('-', '');
      if (!ValidadeCNPJ(data.cnpj)) {
        toast.warn('CNPJ inválido');
        return -1;
      }
      data.cnpj = data.cnpj.replace(/[.-]/gi, '');
    }
    if (data.password !== data.confirm) {
      toast.warn('As senhas são diferentes');
    } else if (!ruleTamMin8 || !ruleHaveLetter || !ruleHaveNumber) {
      toast.warn('Siga as regras de criação de senha.');
    } else {
      data.type = userType;
      if (userType === 'PJ') data.company = data.name;

      const confirmTermsConditions = document.querySelector('[name=terms_conditions]');

      if (confirmTermsConditions.checked) {
        dispatch(signUpRequest(data));
      } else {
        toast.warn('Você deve aceitar os termos e condições de uso para continuar.')
      }
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          paddingTop: '20px',
        }}
      >
        <input
          style={{ width: '24px', alignItems: 'center' }}
          type="radio"
          id="userType"
          name="gender"
          value=""
          checked={userType === 'PF'}
          onClick={() => setUserType('PF')}
        />
        <label style={{ margin: 0, paddingRight: '8px' }} htmlFor="userType">
          Pessoa Física
        </label>
        <input
          style={{ width: '24px', alignItems: 'center' }}
          type="radio"
          id="userType"
          name="gender"
          value="Pessoa Jurídica"
          checked={userType === 'PJ'}
          onClick={() => setUserType('PJ')}
        />
        <label style={{ margin: 0 }} htmlFor="userType">
          Pessoa Jurídica
        </label>
      </div>
      <Form onSubmit={handleSubmit}>
        {userType === 'PF' ? (
          <>
            <Input
              label="Nome"
              name="name"
              type="text"
              placeholder="Nome completo"
            />
            <Input
              label="E-mail"
              name="email"
              type="email"
              placeholder="Seu e-mail"
            />
            <Input
              label="CPF"
              name="cpf"
              type="text"
              placeholder="Seu cpf"
              mask="999.999.999-99"
            />
            <Input
              label="Senha"
              name="password"
              type="password"
              placeholder="Sua senha secreta"
              onChange={e => checkRules(e.target.value)}
            />
            <Input
              label="Confirme a senha"
              name="confirm"
              type="password"
              placeholder="Confirme a senha"
            />
          </>
        ) : (
            <>
              <Input
                label="Razão Social"
                name="name"
                type="text"
                placeholder="Razão Social"
              />
              <Input
                label="E-mail"
                name="email"
                type="email"
                placeholder="Seu e-mail"
              />
              <Input
                label="CNPJ"
                name="cnpj"
                mask="99.999.999/9999-99"
                type="text"
                placeholder="Seu cnpj"
              />
              <Input
                label="Senha"
                name="password"
                type="password"
                onChange={e => checkRules(e.target.value)}
                placeholder="Sua senha secreta"
              />
              <Input
                label="Confirme a senha"
                name="confirm"
                type="password"
                placeholder="Confirme a senha"
              />
            </>
          )}
        <div
          className="row"
          style={{
            width: '341px',
            marginLeft: '0px',
            marginRight: '0px',
          }}
        >
          <div className="col-6" style={{ paddingLeft: '0px' }}>
            <CheckItem label="8 caracteres" status={ruleTamMin8} />
          </div>
          <div className="col-6" style={{ paddingLeft: '0px' }}>
            <CheckItem label="Um minúsculo" status={ruleMin1} />
          </div>
        </div>
        <div
          className="row"
          style={{
            width: '341px',
            marginLeft: '0px',
            marginRight: '0px',
          }}
        >
          <div className="col-6" style={{ paddingLeft: '0px' }}>
            <CheckItem label="Um maiúsculo" status={ruleMai1} />
          </div>
          <div className="col-6" style={{ paddingLeft: '0px' }}>
            <CheckItem label="Um número" status={ruleHaveNumber} />
          </div>
        </div>
        <div className="row my-4 align-items-start">
          <div className="col-auto pr-0 pt-1">
            <input type="checkbox" className="form-control" name="terms_conditions" style={{ width: '16px', height: '16px', }} />
          </div>
          <div className="col text-left pl-1">
            Li e concordo com os <a href="PRJ-Termos-e-condições-de-uso-CD-20200428.pdf" target="_blank" className="text-underline"><u>termos e condições de uso</u></a>.
          </div>
        </div>
        <button type="submit">{loading ? 'Enviando...' : 'Cadastrar'}</button>

        <Link to="/">Ja tenho login</Link>
      </Form>
    </>
  );
}
