/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

import './styles.css';

export default function Input({ name, label, mask, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      {!mask ? (
        <input
          ref={inputRef}
          id={fieldName}
          defaultValue={defaultValue}
          {...rest}
        />
      ) : (
          <InputMask
            mask={mask}
            ref={inputRef}
            id={fieldName}
            defaultValue={defaultValue}
            {...rest}
          />
        )}

      {error && <span style={{ color: '#f00' }}>{error}</span>}
    </>
  );
}
