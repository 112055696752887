import styled from 'styled-components';
import colors from 'styles/defaultColors';

export const Container = styled.div.attrs({
  className: 'row bg-white position-relative',
})`
  border-bottom: 5px solid ${colors.darkBlue};
  padding-bottom: 2px;
  z-index: 10;
  width: 100%;
`;

export const Logo = styled.img.attrs(props => ({ height: '70px' }))`
  cursor: pointer;
`;
