import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #1c3743 0%, #019fb4 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContainerImg = styled.img`
  max-width: ${props => props.maxSize}px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 315px;
  text-align: center;

  align-items: center;
  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: flex-start;
    input {
      background: rgba(0, 0, 0, 0.5);
      border: 0;
      border-radius: 4px;
      height: 44px;
      font-weight: 600;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }
    span {
      color: #f64c75;
      align-self: flex-start;
      margin: 0 0 10px 5px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #299fba;
      color: #fff;
      border: 0;
      width: 100%;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#f94d6a')};
      }
    }
    a {
      align-self: center;
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      font-weight: bold;
      &:hover {
        opacity: 1;
      }
    }

    label {
      align-self: baseline;
    }
  }
`;
