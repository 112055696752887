import styled from 'styled-components';
import { MdCheckCircle } from 'react-icons/md';

export const Container = styled.div`
  display: flex;
  p {
    margin: 0 8px;
    font-weight: bold;
    color: ${props =>
    props.status !== undefined
      ? props.status
        ? '#45a900'
        : '#f00000'
      : '#fff'};
  }

  svg {
    color: ${props =>
    props.status !== undefined
      ? props.status
        ? '#45a900'
        : '#f00000'
      : '#fff'};
  }
`;

export const Img = styled(MdCheckCircle)``;
