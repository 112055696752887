const colors = {
  main: '#1aa5be',
  darkGray: '#6a6a6a',
  darkBlue: '#00505b',
  lightGray: '#e2e4eb',
  lightBlue: '#299fba',

  darkBlue2: '#102835',
  lightBlue2: '#269ccb',
};

export default colors;
