import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import Card from 'components/Card';
import { signOut } from 'store/modules/auth/actions';
import LogoID from 'assets/img_iddigital.png';
import LogoService from 'assets/img_servicosdigitais.png';
import Cookie from 'js-cookie';

export default function RedirectStage() {
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    async function verifyToken() {
      const tokenCookie = Cookie.get('gt-tmp');

      if (process.env.NODE_ENV !== 'development' && tokenCookie !== token)
        dispatch(signOut());

      try {
        const response = await api.get('/auth');
      } catch (error) {
        dispatch(signOut());
      }
    }
    verifyToken();
  }, [dispatch, token]);

  return (
    <>
      <Card
        logo={LogoID}
        url="https://meuid.growthtech.com.br"
        description="A ID é o método pelo qual a plataforma confere a identidade da pessoa física ou jurídica que realizará qualquer serviço cartorário. Dados cadastrais e um reconhecimento facial biométrico são validados junto à bases de dados oficiais do governo."
      />
      <Card
        logo={LogoService}
        url="https://meucartorio.growthtech.com.br"
        description="É uma solução que permite que todo cidadão requeira junto ao Cartório os serviços competentes de maneira 100% virtual, do conforto de sua casa e com toda validade jurídica pertinente, dando maior eficiência e segurança em um menor espaço de tempo."
      />
    </>
  );
}
